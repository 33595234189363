<template>
  <a @click="modalShow = true" class="custom-btn delete-btn">
    <bootstrap-icon :icon="icon" size="1x" />
    <b-modal
      v-model="modalShow"
      modal-class="delete-dialog"
      cancel-title="Cancel"
      ok-title="Delete"
      @ok="confirmDelete"
    >
      <h3>{{ $t("messages.confirmDelete") }}</h3>
    </b-modal>
  </a>
</template>

<script>
import { BModal } from "bootstrap-vue-3";

export default {
  name: "DeleteBtn",
  props: {
    icon: { type: String, default: 'trash' }
  },
  components: {
    BModal,
  },
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    confirmDelete() {
      this.$emit("pressDelete");
    },
  },
};
</script>